const getCorrectMetric = (input: any) => {
    try {
        const prefferedSystem = localStorage.getItem("metricSystem");
        if (prefferedSystem && prefferedSystem == "miles") {
            return (input / 1.609).toFixed(1);
        } else {
            return input;
        }
    } catch {
        return input;
    }
};

const getCorrectSI = () => {
    try {
        const prefferedSystem = localStorage.getItem("metricSystem");
        if (prefferedSystem && prefferedSystem == "miles") {
            return "Mi";
        } else {
            return "Km";
        }
    } catch {
        return "Km";
    }
};

const convertToFeet = (x:number) => {
    try{
    const prefferedSystem = localStorage.getItem("metricSystem");
        if (prefferedSystem && prefferedSystem == "miles") {
            return Math.floor(x * 3.281);
        }
        else{
            return x;
        }
    }catch{
        return x;
    }
}

1.609

const getCorrectSpeed = (x:number) =>{
    try{
        const prefferedSystem = localStorage.getItem("metricSystem");
            if (prefferedSystem && prefferedSystem == "miles") {
                return (x * 1.609);
            }
            else{
                return x;
            }
        }catch{
            return x;
        }
}

export { getCorrectMetric, getCorrectSI, convertToFeet, getCorrectSpeed };
