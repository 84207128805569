const myImageLoader = ({ src, width,height, quality }) => {
    if (src.includes("https://storage.googleapis")) {
        let new_src = src.replace("https://storage.googleapis.com/nestelop-production.appspot.com", "");
       
        return `https://runagain.b-cdn.net${new_src}?width=${width}&height=${height}&quality=${quality || 75}`;
    } else if(src.includes("firebasestorage.googleapis.com")){
        let new_src = decodeURIComponent(src).replace(
            /^https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/[^/]+\/o\//, ""
          ).replace(/\?alt=media&token=[^&]+$/, "");
        
        return `https://runagain.b-cdn.net/${new_src}?width=${width}&height=${height}&quality=${quality || 75}`;
    }
    
    else {
        return src;
    }
};

export default myImageLoader;
