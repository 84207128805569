import { useState, forwardRef, useEffect } from "react";
import { useRouter } from "next/router";
import * as routes from "@/constants/routes";
import {
    Box,
    Container,
    Grid,
    Button,
    AppBar,
    DialogTitle,
    DialogActions,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";

import styling from "./styles/searchComponent.module.scss";
import useTranslation from "next-translate/useTranslation";
import { withSearch } from "../../Firebase/Search";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { defaultState } from "@/components/Firebase/Search/search";
import { BuildFilterOption } from "@/content/findRace/components/filter/buildFilterOptions";
import dynamic from "next/dynamic";
const DesktopFilter: any = dynamic(
    () => import("@/content/findRace/components/filter/desktopFilter")
);

import { ReactComponent as SearchIcon } from "@/assets/searchBroken.svg";
import { ReactComponent as Filter } from "@/assets/filterBroken.svg";
import { ReactComponent as Close } from "@/assets/exit.svg";
import { ReactComponent as ClearIcon } from "@/assets/singleExit.svg";
import { ReactComponent as ArrowRight } from "@/assets/arrowRight.svg";
import SearchWithSuggestions from "./SearchSuggestions";
import { updateQuery } from "@/content/findRace/components/filter/desktopFilter";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const classes = {
    iconBox: {
        width: "45px",
        height: "40px",
        display: { xs: "none", lg: "block" },
        backgroundColor: "#222222",
        borderRadius: "100%",
        paddingTop: "10px",
        mr: "6px",
        // mt: "5px",
        cursor: "pointer",
    },
    iconFilter: {
        cursor: "pointer",
        width: "15px",
        height: "23px",

        circle: {
            fill: "#525252",
            stroke: "#525252",
            "&:active": {
                fill: "#FF8640",
                stroke: "#FF8640",
            },
        },
        rect: {
            fill: "#525252",
            "&:active": {
                fill: "#FF8640",
            },
        },
        mt: 0.5,
        color: "#525252",
        "&:hover": {
            color: "#FF8640",
        },
    },
    iconFilterBox: {
        display: { xs: "block", lg: "none" },
        borderRadius: "100%",
        cursor: "pointer",
        mt: "10px",
    },
};

function SearchComponent(props: any) {
    const [openSearch, setOpenSearch] = useState(false);
    const [searchText, setSearchText] = useState("");

    const { t } = useTranslation("translation");
    const router = useRouter();

    const handleURLChange = () => {
        if (window && window.location.search) {
            const searchState: any = new URLSearchParams(window.location.search);
            const searchValue = searchState.get("searchText");
            if (searchValue) {
                setSearchText(searchValue);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("popstate", handleURLChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const UpdateState = async (key: string, value: string) => {
        setSearchText(value);
        props.UpdateGlobalState(key, value);
    };

    const CheckWhichKeyIsPressed = (key: string, value: string) => {
        if (key === "searchText" && value === "Enter") {
            GoToFindRunWithSearch();
        }
    };

    useEffect(() => {
        if (props.mobile) {
            setOpenSearch(props.startTutorial);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startTutorial]);

    useEffect(() => {
        if (openSearch) {
            document.body.style.position = "fixed";
        } else {
            document.body.style.position = "initial";
        }
    }, [openSearch]);

    const GoToFindRunWithSearch = () => {
        const filterOptions = BuildFilterOption({ ...props.stateArray }, null, null, null);
        props.UpdateRuns(props.stateArray.searchText, filterOptions);

        if (
            !location.pathname.includes(routes.FIND_RUN) &&
            !location.pathname.includes(routes.CATEGORY)
        ) {
            router.push({
                pathname: routes.FIND_RUN,
                query: { searchText: searchText, fromDate: new Date().toLocaleDateString(), p: 1 },
            });
        } else {
            updateQuery("searchText", searchText, router.asPath);
        }
    };

    const GoToRunWithText = (search_string: string) => {
        const filterOptions = BuildFilterOption({ ...props.stateArray }, null, null, null);
        props.UpdateRuns(search_string, filterOptions);
        if (
            !location.pathname.includes(routes.FIND_RUN) &&
            !location.pathname.includes(routes.CATEGORY)
        ) {
            router.push({ pathname: routes.FIND_RUN, query: { searchText: searchText } });
        }
    };

    const GoToFindRun = () => {
        if (props.stateArray.searchText != "") {
            router.push({
                pathname: routes.FIND_RUN,
                query: { searchText: props.stateArray.searchText },
            });
        } else {
            router.push(routes.FIND_RUN);
        }
    };

    const ResetFilters = async () => {
        await props.ResetState(JSON.parse(JSON.stringify(defaultState)));
        await props.UpdateRuns("", "");
    };

    const handleClick = () => {
        if (!props.desktop) {
            setOpenSearch(true);
        }
    };

    const handleSearchClose = () => {
        if (
            !location.pathname.includes(routes.FIND_RUN) &&
            !location.pathname.includes(routes.CATEGORY)
        ) {
            GoToFindRun();
        }
        setOpenSearch(false);
    };

    return (
        <>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid
                        item
                        className={styling.searchBox}
                        xs={12}
                        lg={10}
                        id="my-first-step"
                        onClick={() => handleClick()}
                    >
                        <Box
                            sx={{
                                display: { xs: "flex", lg: "none" },
                                justifyContent: "center",
                                alignItems: "center",
                                mr: "10px",
                            }}
                        >
                            <SearchIcon width={20} />
                        </Box>
                        {!props.desktop ? (
                            <Box
                                id="searchStartField"
                                onClick={() => handleClick()}
                                sx={{
                                    width: "100%",
                                    display: { xs: "flex", lg: "none" },
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mr: "10px",
                                }}
                            >
                                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                                    {t("common.onBoarding.texts.0") ?? ""}
                                </Typography>
                            </Box>
                        ) : (
                            <SearchWithSuggestions
                                onChange={(e) => {
                                    setSearchText(e);
                                    UpdateState("searchText", e);
                                }}
                                GoToFindRunWithSearch={(search_string: string) =>
                                    GoToRunWithText(search_string)
                                }
                                checkKey={(e: any) => CheckWhichKeyIsPressed("searchText", e)}
                                firebase={props.firebase}
                            />
                        )}
                        <Box sx={classes.iconBox}>
                            <SearchIcon
                                width={22}
                                fill="white"
                                onClick={() => {
                                    GoToFindRunWithSearch();
                                }}
                            />
                        </Box>

                        <Box sx={classes.iconFilterBox}>
                            <Filter
                                width={30}
                                onClick={() => {
                                    GoToFindRunWithSearch();
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                fullScreen
                disableScrollLock
                scroll="paper"
                open={openSearch}
                keepMounted
                onClose={handleSearchClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        backgroundColor: "#fcfcfc",
                        borderRadius: "20px 20px 0px 0px",
                        bottom: 75,
                        position: "absolute",
                    }}
                >
                    <DialogTitle>
                        <Box
                            sx={{
                                float: "right",
                                position: "absolute",
                                top: 20,
                                right: 20,
                                cursor: "pointer",
                            }}
                        >
                            <Close
                                width={25}
                                onClick={() => setOpenSearch(false)}
                                aria-label="close"
                            />
                        </Box>
                    </DialogTitle>
                    <Box
                        sx={{
                            width: { xs: "80%", sm: "60%" },
                            paddingTop: "10px",
                            maxHeight: "70vh",
                            overflowY: "scroll",
                            margin: "0 auto",
                        }}
                        id={props.mobile ? "mobile-filters" : ""}
                    >
                        <DesktopFilter
                            UpdateRuns={(query: string, filterOptions: string) =>
                                props.UpdateRuns(query, filterOptions)
                            }
                            totalNumber={props.totalNumber}
                            mobile={props.mobile}
                            openSearch={openSearch}
                            onClose={() => setOpenSearch(false)}
                        />
                    </Box>
                    <DialogActions>
                        <AppBar
                            position="fixed"
                            sx={{
                                top: "auto",
                                bottom: 0,
                                backgroundColor: "#fcfcfc",
                                width: "100%",
                                boxShadow: "none",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    height: "80px",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    width: { xs: "100%", sm: "65%" },
                                    backgroundColor: "#fcfcfc",
                                    margin: "0 auto",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{ width: "45%", lineHeight: "20px" }}
                                    onClick={ResetFilters}
                                >
                                    <Typography variant="body1" sx={{ mr: "5px" }}>
                                        {t("findRace.search.removeFilters")}
                                    </Typography>
                                    <ClearIcon width={12} stroke="#343434" />
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSearchClose}
                                    sx={{ width: "45%" }}
                                >
                                    <Typography variant="body1" sx={{ mr: "5px" }}>
                                        {t("findRace.search.seeResults")}
                                    </Typography>
                                    <ArrowRight width={12} stroke="#fff" />
                                </Button>
                            </Box>
                        </AppBar>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}

export default withSearch(SearchComponent);
